import {
  Answer,
  RendererAnswersState,
} from "@quillforms/renderer-core/build-types/store/types";
import blocks from "./blocks";

export enum OrderedField {
  WelcomeScreen = "welcome-screen",
  CompanyName = "company-name",
  CompanyActivities = "company-activity",
  HeadquartersLocation = "headquarters-location",
  Version = "version",
  InfrastructureType = "infrastructure-type",
  DatacenterLocation = "server-location",
  IsmsScope = "isms-scope",
  LeadPriority = "lead-priority",
  Fullname = "fullname",
  Email = "email",
  NecessaryInfoProvided = "necessary-info-provided",
  CompanyType = "company-type",
  ContactRole = "contact-role",
  CompanyRole = "company-headcount",
  Phonenumber = "phonenumber",
  DefaultThankyouScreen = "default_thankyou_screen",
}

const orderedFields = [
  OrderedField.CompanyName,
  OrderedField.CompanyActivities,
  OrderedField.HeadquartersLocation,
  OrderedField.Version,
  OrderedField.InfrastructureType,
  OrderedField.DatacenterLocation,
  OrderedField.IsmsScope,
  OrderedField.LeadPriority,
  OrderedField.Fullname,
  OrderedField.Email,
  OrderedField.NecessaryInfoProvided,
  OrderedField.CompanyType,
  OrderedField.ContactRole,
  OrderedField.CompanyRole,
  OrderedField.Phonenumber,
];

export const getCorrespondingFieldAnswer = (
  orderedField: OrderedField,
  formAnswers: RendererAnswersState
): Array<[string, Answer]> => {
  const infrastructureType: string | undefined = formAnswers[
    OrderedField.InfrastructureType
  ]?.value as string | undefined;

  console.log(infrastructureType);

  switch (orderedField) {
    case blocks(infrastructureType).find((x) => x.id === orderedField)?.name !==
      "group" && orderedField:
      return [[orderedField, formAnswers[orderedField]]];
    case blocks(infrastructureType).find((x) => x.id === orderedField)?.name ===
      "group" && orderedField:
      const innerBlocks = blocks(infrastructureType).find(
        (x) => x.id === orderedField
      )?.innerBlocks!;
      return innerBlocks.map((x) => [x.id, formAnswers[x.id]]);
    default:
      return [];
  }
};

export default orderedFields;
