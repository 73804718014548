import { FormTheme } from "@quillforms/types/build-types";

export const theme: Partial<FormTheme> = {
  font: "Poppins",
  backgroundColor: "#ffffff",
  backgroundImage: "",
  logo: {},
  questionsColor: "#000",
  answersColor: "#000000",
  buttonsFontColor: "#fff",
  buttonsBgColor: "#4156fd",
  buttonsBorderRadius: 25,
  errorsFontColor: "#fff",
  errorsBgColor: "#b21928",
  progressBarFillColor: "#000",
  progressBarBgColor: "#ccc",
};

export const themesList: Theme[] = [
  {
    id: 55,
    properties: {
      font: "Poppins",
      backgroundColor: "#264268",
      backgroundImage: "",
      logo: {},
      questionsColor: "#ffffff",
      answersColor: "#ffffff",
      buttonsFontColor: "#ffffff",
      buttonsBgColor: "#4156fd",
      buttonsBorderRadius: 25,
      errorsFontColor: "#ffffff",
      errorsBgColor: "#b21928",
      progressBarFillColor: "#000",
      progressBarBgColor: "#ccc",
    },
  },
];
