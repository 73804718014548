import React, { SVGProps } from "react";
import { ReactComponent as MyLogo0 } from "../assets/LogoLight.svg";
import { OrderedField } from "./ordered-fields";

export const TermsAndConditions = () => (
  <div
    style={{
      // position: "fixed",
      flexBasis: "content",
      flexShrink: 0,
      padding: "24px 0",
      zIndex: 3000,
      bottom: "24px",
      font: "12px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      color: "#808080",
    }}
  >
    <div
      style={{
        padding: "0 36px",
        fontFamily: "Poppins",
      }}
    >
      {"By starting this form, you certify that you have read and accepted "}{" "}
      <a
        href="https://www.ouwba.com/politique-de-confidentialite"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none", color: "#4156fd" }}
      >
        {"our general terms and conditions"}
      </a>
      {"."}
    </div>
  </div>
);

const MyLogo = (props: SVGProps<SVGSVGElement>) => {
  return (
    <MyLogo0
      style={{
        transitionProperty: "fill, stroke",
        transitionDuration: "0.5s",
        transitionTimingFunction: "linear",
        transitionDelay: "0.2s",
        marginLeft: "8px",
      }}
      {...props}
    />
  );
};

const LogoFilled = ({
  color,
  faded = false,
}: {
  color: "green" | "yellow";
  faded?: boolean;
}) => (
  <MyLogo
    stroke={`${color === "yellow" ? "#ffffff00" : "#ffffff00"}`}
    fill={`${color === "yellow" ? "#89664c" : "#4156fd"}`}
  />
);

const LogoEmpty = () => (
  <MyLogo stroke="black" strokeWidth="8px" fill="#ffffff00" />
);

const f = (s: OrderedField, orderedFields: OrderedField[]): JSX.Element => {
  const index = orderedFields.indexOf(s);

  return (
    <>
      {index === 0 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 0 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 1 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 1 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 2 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 2 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 3 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 3 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 4 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 4 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 5 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 5 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 6 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 6 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 7 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 7 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 8 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 8 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index === 9 ? (
        <LogoFilled color={"yellow"} faded={true} />
      ) : index >= 9 ? (
        <LogoFilled color={"yellow"} />
      ) : (
        <LogoEmpty />
      )}
      {index >= 11 ? (
        index === 11 ? (
          <LogoFilled color={"green"} faded={true} />
        ) : index >= 11 ? (
          <LogoFilled color={"green"} />
        ) : (
          <LogoEmpty />
        )
      ) : (
        <></>
      )}
      {index >= 11 ? (
        index === 12 ? (
          <LogoFilled color={"green"} faded={true} />
        ) : index >= 12 ? (
          <LogoFilled color={"green"} />
        ) : (
          <LogoEmpty />
        )
      ) : (
        <></>
      )}
      {index >= 11 ? (
        index === 13 ? (
          <LogoFilled color={"green"} faded={true} />
        ) : index >= 13 ? (
          <LogoFilled color={"green"} />
        ) : (
          <LogoEmpty />
        )
      ) : (
        <></>
      )}
      {index >= 11 ? (
        index === 14 ? (
          <LogoFilled color={"green"} faded={true} />
        ) : index >= 14 ? (
          <LogoFilled color={"green"} />
        ) : (
          <LogoEmpty />
        )
      ) : (
        <></>
      )}
    </>
  );
};

export const LogoGauge = ({
  currentBlock,
  orderedFields,
}: {
  currentBlock: OrderedField;
  orderedFields: OrderedField[];
}) => (
  <div
    style={{
      position: "fixed",
      zIndex: 3000,
      bottom: "24px",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  >
    {f(currentBlock, orderedFields)}
  </div>
);
