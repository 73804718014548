import { useSelect } from "@wordpress/data";

const useCurrentBlock = () => {
  const { currentBlockId } = useSelect((select: any) => {
    return {
      currentBlockId: select("quillForms/renderer-core").getCurrentBlockId(),
    };
  }, []);

  return currentBlockId; // blocks.find((block: any) => block.id === currentBlockId);
};

export default useCurrentBlock;
