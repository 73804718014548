import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client"; // Import from 'react-dom/client' for React 18
import App from "./App";

import * as Sentry from "@sentry/react";
import ReactGA from "react-ga4";

// Initialize Sentry for error tracking and performance monitoring
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Initialize Google Analytics
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS!);

const rootElement = document.getElementById("root");

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement); // Create root using React 18's createRoot
  root.render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}
