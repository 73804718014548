import { FormBlock } from "@quillforms/types/build-types";

const blocks: (_: string | undefined) => FormBlock[] = (infrastructureType) => {
  return [
    {
      name: "welcome-screen",
      id: "welcome-screen",
      attributes: {
        label: "Generate your ISMS manual!",
        description: "A few questions to help us help you",
        buttonText: "Let's go",
        attachment: {
          type: "image",
          url: "/ouwba-welcome.svg",
        },
      },
    },
    {
      name: "short-text",
      id: "company-name",
      attributes: {
        required: true,
        label: "Let's start by your company name",
      },
    },
    {
      name: "short-text",
      id: "company-activity",
      attributes: {
        required: true,
        label:
          'Tell us more about "{{field:company-name}}", its context and its activities',
        description:
          "The objective is to briefly describe your company to your auditor",
      },
    },
    {
      name: "short-text",
      id: "headquarters-location",
      attributes: {
        required: true,
        label: "Where are your company headquarters?",
        description:"(e.g. 55 rue du Faubourg-Saint-Honoré 75008 Paris)"

      },
    },
    {
      name: "dropdown",
      id: "version",
      attributes: {
        required: true,
        label: "What version of the document do you want to generate?",
        choices: [
          { value: "v1", label: "Version 1.0" },
          { value: "v2", label: "Version 2.0" },
        ],
      },
    },
    {
      name: "multiple-choice",
      id: "infrastructure-type",
      attributes: {
        required: true,
        multiple: false,
        verticalAlign: false,
        label:
          "Does your IT infrastructure use Cloud, On-premise or Hybrid architecture?",
        choices: [
          {
            label: "Cloud",
            value: "cloud",
          },
          {
            label: "On-premise",
            value: "onPremise",
          },
          {
            label: "Hybrid",
            value: "hybrid",
          },
        ],
      },
    },
    (() => {
      switch (infrastructureType) {
        case "onPremise":
          return {
            name: "short-text",
            id: "server-location",
            attributes: {
              required: true,
              label: "Where are your server located?",
              description: "(address of the office)",
            },
          };
        case "hybrid":
          return {
            id: "server-location",
            name: "group",
            attributes: {
              label: "Where are your servers...",
            },
            innerBlocks: [
              {
                name: "short-text",
                id: "office-location",
                attributes: {
                  required: true,
                  label: "on premise (address of the office)?",
                },
              },
              {
                name: "short-text",
                id: "datacenter-location",
                attributes: {
                  required: true,
                  label: "in the cloud (e.g. Google's datacenter in Paris)?",
                },
              },
            ],
          };
        default:
          return {
            name: "short-text",
            id: "server-location",
            attributes: {
              required: true,
              label: "Where your servers are located?",
              description: "(e.g. Google's datacenter in Paris)",
            },
          };
      }
    })(),
    {
      name: "short-text",
      id: "isms-scope",
      attributes: {
        required: true,
        label: "What is the target scope of your ISMS?",
        description:
          "The scope of the Information Security Management System (ISMS) defines the parts of the company (assets, processes, teams) covered by information security measures for ISO 27001 certification.",
      },
    },
    {
      name: "multiple-choice",
      id: "lead-priority",
      attributes: {
        required: true,
        multiple: false,
        verticalAlign: false,
        label: "When do you need to be certified?",
        choices: [
          {
            label: "Right now",
            value: "now",
          },
          {
            label: "Less than 3 months",
            value: "lessThan3Months",
          },
          {
            label: "Less than 6 months",
            value: "lessThan6Months",
          },
          {
            label: "More than 6 months",
            value: "moreThan6Months",
          },
        ],
      },
    },
    {
      id: "fullname",
      name: "group",
      attributes: {
        label: "Remember me, what is  ...",
      },
      innerBlocks: [
        {
          name: "short-text",
          id: "firstname",
          attributes: {
            required: true,
            label: "your first name",
          },
        },
        {
          name: "short-text",
          id: "lastname",
          attributes: {
            required: true,
            label: "and your last name?",
          },
        },
      ],
    },
    {
      name: "email",
      id: "email",
      attributes: {
        required: true,
        label: "An email to share the ISMS manual to you",
      },
    },

    {
      name: "statement",
      id: "necessary-info-provided",
      attributes: {
        label: "And it's done!",
        description:
          "You are going to be shared the ISMS manual. Check your email <strong>{{field:email}}</strong>.\n",
        customHTML:
          '<div style="margin-top: 36px">To get to know you better and build solutions adapted to you, </div><div>we have some additional questions </div>',
        buttonText: "Continue", // Default: "Continue"
        quotationMarks: false, // Default: true
        themeId: 55,
      },
    },
    {
      name: "multiple-choice",
      id: "company-type",
      attributes: {
        required: false,
        multiple: false,
        verticalAlign: false,
        label: "What kind of company are you in?",
        choices: [
          {
            label: "Startup",
            value: "startup",
          },
          {
            label: "Scaleup",
            value: "scaleup",
          },
          {
            label: "SME",
            value: "sme",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
      },
    },
    {
      name: "multiple-choice",
      id: "contact-role",
      attributes: {
        required: false,
        multiple: false,
        verticalAlign: false,
        label: 'What role do you have in "{{field:company-name}}"?',
        choices: [
          {
            label: "Founder",
            value: "founder",
          },
          {
            label: "CTO",
            value: "cto",
          },
          {
            label: "CISO",
            value: "ciso",
          },
          {
            label: "Developer",
            value: "developer",
          },
          {
            label: "Sales",
            value: "sales",
          },
          {
            label: "Other",
            value: "other",
          },
        ],
      },
    },
    {
      name: "multiple-choice",
      id: "company-headcount",
      attributes: {
        required: false,
        multiple: false,
        verticalAlign: false,
        label: "How many people in the company?",
        choices: [
          {
            label: "0 à 10",
            value: "0-10",
          },
          {
            label: "10 à 50",
            value: "10-50",
          },
          {
            label: "50 à 100",
            value: "50-100",
          },
          {
            label: "+ de 100",
            value: "100+",
          },
        ],
      },
    },
    {
      name: "short-text",
      id: "phonenumber",
      attributes: {
        required: false,
        label: "A phone number to have a chat?",
      },
    },
  ];
};

export default blocks;
