const postPartialInfo = async (
  uuid: string,
  fieldAnswerArray: Array<[string, string]>,
  token: string
) =>
  process.env.REACT_APP_API_URL &&
  (await fetch(
    `${process.env.REACT_APP_API_URL}/api/document-generation/${uuid}/partial-information`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",

        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      body: JSON.stringify(
        fieldAnswerArray.map(([fieldToSend, dataToSend]) => ({
          type: fieldToSend,
          value: dataToSend,
        }))
      ),
    }
  ));

export default postPartialInfo;
