const createSecurityDocument = async (
  uuid: string,
  companyName: string,
  companyActivities: string,
  firstname: string,
  lastname: string,
  email: string,
  version: string,
  infrastructure: Array<string>,
  premises: Array<string>,
  ismsScope: string,
  infrastructureType: string,
  token: string
) =>
  process.env.REACT_APP_API_URL &&
  (await fetch(`${process.env.REACT_APP_API_URL}/api/smsi-manual`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",

      Authorization: `Bearer ${token}`,
    },
    credentials: "include",
    body: JSON.stringify({
      companyName,
      companyActivities,
      firstname,
      lastname,
      email,
      version,
      infrastructure,
      premises,
      ismsScope,
      infrastructureType,
    }),
  }));

export default createSecurityDocument;
