import { FormMessages } from "@quillforms/types/build-types";

const messages: FormMessages = {
  "label.button.ok": "Next", // Default: 'Ok'
  "label.hintText.enter": "Press <strong>Enter ↵</strong>", // Default: "press <strong>Enter ↵</strong>",
  "label.hintText.multipleSelection": "Choose as many as you like", // Default: "Choose as many as you like"
  "block.dropdown.placeholder": "Type or select an option", // Default: "Type or select an option"
  "block.dropdown.noSuggestions": "No Suggestions!", // Default: "No Suggestions!"
  "block.shortText.placeholder": "Enter your answer", // Default: "Type your answer here"
  "block.longText.placeholder": "Enter your answer", // Default: "Type your answer here"
  "block.longText.hint":
    "<strong>Shift ⇧ + Enter ↵</strong> to make a line break", // Default: "<strong>Shift ⇧ + Enter ↵</strong> to make a line break"
  "block.number.placeholder": "Type your answer here", // Default: "Type your answer here"
  "block.email.placeholder": "Type your email here", // Default: "Type your email here"
  "block.defaultThankYouScreen.label":
    'Thanks to have opened up\n\n See you soon on the <a href="https://www.linkedin.com/company/ouwba-certification">ouwba community<a>', // Default: "Thanks for filling this in.\n\n We will contact you soon"
  "label.hintText.key": "Enter", // Default: "Key"
  "label.progress.percent": "{{progress:percent}}% completed", // Default: "{{progress:percent}}% completed"
  "label.errorAlert.required":
    "We need this info to generate your ISMS manual, don't be shy !", // Default: "This field is required!"
  "label.errorAlert.date": "Invalid date!", // Default: "Invalid date!"
  "label.errorAlert.number": "Numbers only!", // Default: "Numbers only!"
  "label.errorAlert.selectionRequired": "Please make at least one selection!", // Default: "Please make at least one selection!"
  "label.errorAlert.email": "This is not an email!", // Default: "Invalid email!"
  "label.errorAlert.url": "Invalid url!", // Default: "Invalid url!"
  "label.errorAlert.range":
    "Please enter a number between {{attribute:min}} and {{attribute:max}}", // Default: "Please enter a number between {{attribute:min}} and {{attribute:max}}"
  "label.errorAlert.minNum":
    "Please enter a number greater than {{attribute:min}}", // Default: "Please enter a number greater than {{attribute:min}}"
  "label.errorAlert.maxNum":
    "Please enter a number lower than {{attribute:max}}", // Default: "Please enter a number lower than {{attribute:max}}"
  "label.errorAlert.maxCharacters": "Maximum characters reached!", // Default: "Maximum characters reached!"
  "label.submitBtn": "Send my answers",
};

export default messages;
