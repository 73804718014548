import {
  Form,
  useFieldAnswer,
  useFormAnswers,
} from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
// @ts-expect-error
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./styles.css";
import React, { useEffect, useState } from "react";

// @ts-expect-error
import { SubmissionData, SubmissionDispatchers } from "./types";
import ReactGA from "react-ga4";
import * as uuidGenerator from "uuid";
import messages from "./custom-form/messages";
import orderedFields, {
  OrderedField,
  getCorrespondingFieldAnswer,
} from "./custom-form/ordered-fields";
import blocks from "./custom-form/blocks";
import { LogoGauge, TermsAndConditions } from "./custom-form/Footers";
import { theme, themesList } from "./custom-form/themes";
import useCurrentBlock from "./custom-form/hooks/useCurrentBlock";
import getFunnelToken from "./custom-form/api-calls/get-funnel-token";
import postPartialInfo from "./custom-form/api-calls/post-partial-info";
import createSecurityDocument from "./custom-form/api-calls/create-security-document";

registerCoreBlocks();
const App = () => {
  const [uuid, setUuid] = useState<string | undefined>(undefined);
  const [token, setToken] = useState<string | undefined>(undefined);
  const [apiCallStatus, setApiCallStatus] = useState<
    "pending" | "notYet" | "done" | "error"
  >("notYet");

  useEffect(() => {
    setUuid(uuidGenerator.v4());
  }, []);

  useEffect(() => {
    uuid && setToken("blabla") /*getFunnelToken(uuid).then(async (res) => {
        setToken((await (res as Response).json())["funnel-token"]);
      })*/;
  }, [uuid]);

  const currentBlock: OrderedField = useCurrentBlock();

  const formAnswers = useFormAnswers();

  useEffect(() => {
    const orderedFields1 = [
      OrderedField.WelcomeScreen,
      ...orderedFields,
      OrderedField.DefaultThankyouScreen,
    ];
    const previousIndex: number = orderedFields1.indexOf(currentBlock) - 1;

    const correspondingFieldAnswer = getCorrespondingFieldAnswer(
      orderedFields1[previousIndex],
      formAnswers
    );

    const fieldToSend =
      previousIndex >= 1 ? orderedFields1[previousIndex] : undefined;

    const dataToSend =
      fieldToSend && previousIndex >= 1
        ? (formAnswers[fieldToSend]?.value as string)
        : undefined;

    previousIndex >= 1 &&
      uuid &&
      token &&
      postPartialInfo(
        uuid,
        correspondingFieldAnswer.map(([field, answer]) => [
          field,
          answer?.value as string,
        ]),
        token
      );

    /*
      CompanyName = "company-name",
  companyActivities = "company-activity",
  Email = "email",
  Firstname = "firstname",
  Lastname = "lastname",
  Version = "version",
  DatacenterLocation = "datacenter-location",
  IsmsScope = "isms-scope",
  InfrastructureType = "infrastructure-type",
    */

    const companyName: string | undefined = formAnswers["company-name"]
      ?.value as string | undefined;
    const companyActivities: string | undefined = formAnswers[
      "company-activity"
    ]?.value as string | undefined;
    const email: string | undefined = formAnswers.email?.value as
      | string
      | undefined;
    const firstname: string | undefined = formAnswers.firstname?.value as
      | string
      | undefined;
    const lastname: string | undefined = formAnswers.lastname?.value as
      | string
      | undefined;
    const version: string | undefined = formAnswers.version?.value as
      | string
      | undefined;
    const headquartersLocation: string | undefined = formAnswers[
      "headquarters-location"
    ]?.value as string | undefined;
    const serverLocation: string | undefined = formAnswers["server-location"]
      ?.value as string | undefined;
    const datacenterLocation: string | undefined = formAnswers[
      "datacenter-location"
    ]?.value as string | undefined;
    const officeLocation: string | undefined = formAnswers["office-location"]
      ?.value as string | undefined;
    const ismsScope: string | undefined = formAnswers["isms-scope"]?.value as
      | string
      | undefined;
    const infrastructureType: string | undefined = (
      formAnswers["infrastructure-type"]?.value as Array<string>
    )?.[0] as string | undefined;

    const premises = (() => {
      switch (infrastructureType) {
        case "cloud":
          return headquartersLocation ? [headquartersLocation] : undefined;
        case "onPremise":
          return headquartersLocation && serverLocation
            ? [headquartersLocation, serverLocation]
            : undefined;
        case "hybrid":
          return headquartersLocation && officeLocation
            ? [headquartersLocation, officeLocation]
            : undefined;
        default:
          return undefined;
      }
    })();

    const infrastructure = (() => {
      switch (infrastructureType) {
        case "cloud":
          return serverLocation ? [serverLocation] : undefined;
        case "onPremise":
          return serverLocation ? [serverLocation] : undefined;
        case "hybrid":
          return datacenterLocation && officeLocation
            ? [datacenterLocation, officeLocation]
            : undefined;
        default:
          return undefined;
      }
    })();

    if (
      uuid &&
      token &&
      companyName &&
      companyActivities &&
      email &&
      firstname &&
      lastname &&
      version &&
      infrastructure &&
      premises &&
      ismsScope &&
      infrastructureType &&
      !(apiCallStatus === "done") &&
      !(apiCallStatus === "pending")
    ) {
      setApiCallStatus("pending");
      createSecurityDocument(
        uuid,
        companyName,
        companyActivities,
        firstname,
        lastname,
        email,
        version,
        infrastructure,
        premises,
        ismsScope,
        infrastructureType,
        token
      )
        .then((res) => {
          if ((res as Response).status === 200) {
            setApiCallStatus("done");
          }
        })
        .catch((error) => {
          setApiCallStatus("error");
          throw Error("An error occured while calling the API: ", error);
        });
    }
  }, [uuid, currentBlock]);

  useEffect(() => {
    currentBlock
      ? ReactGA.send({
          hitType: "pageview",
          page: currentBlock,
          title: currentBlock,
        })
      : ReactGA.send({
          hitType: "pageview",
        });
  }, [currentBlock]);

  const infrastructureType = (
    useFieldAnswer("infrastructure-type") as Array<string | undefined>
  )?.[0];

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Form
        formId={1}
        formObj={{
          blocks: blocks(infrastructureType),
          settings: {
            disableProgressBar: true,
            disableWheelSwiping: false,
            disableNavigationArrows: true,
            animationDirection: "horizontal",
          },
          theme,
          themesList,
          messages,
          hiddenFields: [],
        }}
        applyLogic={false}
        isPreview={false}
        onSubmit={(
          data: SubmissionData,
          {
            completeForm,
            setIsSubmitting,
            goToBlock,
            setSubmissionErr,
            setIsReviewing,
            setFooterDisplay,
          }: SubmissionDispatchers
        ) => {
          setTimeout(() => {
            setIsSubmitting(false);
            completeForm();
          }, 500);
        }}
      />
      {!(
        typeof currentBlock === "undefined" ||
        currentBlock === OrderedField.WelcomeScreen ||
        currentBlock === OrderedField.DefaultThankyouScreen
      ) ? (
        <LogoGauge currentBlock={currentBlock} orderedFields={orderedFields} />
      ) : currentBlock === OrderedField.WelcomeScreen ? (
        <TermsAndConditions />
      ) : (
        <></>
      )}
    </div>
  );
};

export default App;
